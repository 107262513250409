@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";


html, body, div#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.app-container {
  width: 100%;
  padding: 4rem;

  min-height:100vh;
}

div.app-container-inner {
  width: 90%;
  margin: auto;
  padding-top: 6rem;
}

div.app-container-inner div.app-container-header {
  background: #ffffff;
  padding: 2rem 2rem 1rem 2rem;
}

div.app-container-inner div.app-container-content {
  background: #ffffff;
  padding: 1rem 2rem 2rem 2rem;

  display: grid;
  grid-template: "concern s1 data s2 acknowledgement";
  grid-gap: 2rem;
  align-items: start;
  justify-items: center;
}

div.app-container-inner div.app-container-non-ideal {
  background: #ffffff;
  padding: 4rem;
}


div.app-container-inner div.app-container-content > div {
  width: 100%;
  height: 100%;
}

div.app-container-inner div.app-container-content > div table {
  width: 100%;
}

div.app-container.state-concern {
  background: #A63232;
}

div.app-container.state-clear {
  background: #e7eed6;
}

div.grid-area-concern {
  grid-area: concern;
}

div.grid-area-data {
  grid-area: data;
}

div.grid-area-acknowledgement {
  grid-area: acknowledgement;
}



div.grid-area-s1, div.grid-area-s2 {
  background: #f5f5f5;
  align-self: center;
  justify-self: center;
}

div.grid-area-s1 {
  grid-area: s1;
  max-width: 1px;
  max-height: 75%;
}


div.grid-area-s2 {
  grid-area: s2;
  max-width: 1px;
  max-height: 75%;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media only screen and (max-width: 1500px) {

  div.app-container-nav {
    display: none;
  }

  div.app-container-inner {
    width: 100%;
    padding-top: 0rem;
  }

  div.app-container-inner div.app-container-content > div.grid-area-s1 {
    max-width: 75%;
    height: 1px;
  }

  div.app-container-inner div.app-container-content > div.grid-area-s2 {
    max-width: 75%;
    height: 1px;
  }

  div.app-container-inner div.app-container-content {
    background: #ffffff;
    padding: 1rem 2rem 2rem 2rem;

    display: grid;
    grid-template: "acknowledgement" "s1" "concern" "s2" "data";
    grid-gap: 2rem;
    align-items: start;
    justify-items: center;
  }

}


@media only screen and (max-width: 600px) {


  div.app-container {
    width: 100%;
    padding: 1rem;

    min-height:100vh;
  }

  div.app-container-inner {
    width: 95%;
    margin: auto;
    padding-top: 2rem;
  }

  div.app-container-inner div.app-container-content {

    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    grid-gap: 1rem;
  }

}